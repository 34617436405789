<template>
    <footer class="nav-bg">
        <nav class="my-container">
            <div class="left-side-bar"></div>
            <div class="content-bar">
                <div class="footer-up">
                    <div class="content">
                        <router-link to="/hakkimizda" class="text-decoration-none title">
                            HAKKIMIZDA
                        </router-link>
                        <p class="content-text">
                            Diziyle Öğren İngilizce öğrenmek için dizi ve film izleyenlere yönelik olarak 2019'da
                            kurulmuş olan bir platformdur. Ziyaretçilerimiz istedikleri bir dizi veya filmde kullanılan
                            tüm kelimeleri sitemizden veya mobil uygulamamızdan öğrenebilir.
                            <router-link to="/hakkimizda" class="content-text">
                                Detaylı Bilgi
                            </router-link>
                        </p>
                    </div>

                    <div class="content">
                        <router-link to="/blog" class="title">
                            BLOG
                        </router-link>
                        <router-link to="/blog/dizi-ile-ingilizce-ogrenmek-ayrintili-rehber" class="content-text">
                            Dizi İzleyerek İngilizce Öğrenmek Ayrıntılı Rehber
                        </router-link>
                        <router-link to="/blog/ingilizceyi-dizilerden-ogrenmek-isteyenler-icin-en-kolay-8-dizi"
                            class="content-text">
                            İngilizce Öğrenmek İçin En Kolay 8 Dizi
                        </router-link>
                        <router-link to="/blog/ingilizce-dizi-ve-filmlerde-en-sik-kullanilan-5000-kelime"
                            class="content-text">
                            İngilizcede En Çok Kullanılan 5000 Kelime
                        </router-link>
                        <router-link to="/blog/a1-seviye-ingilizce-kelimeler-ve-anlamlari" class="content-text">
                            A1 Seviye İngilizce Kelime Listesi
                        </router-link>
                        <router-link to="/blog/yds-yokdil-sinavlarinda-en-cok-cikmis-600-kelime-listesi"
                            class="content-text">
                            YDS-YÖKDİL En Çok Çıkmış 600 Kelime
                        </router-link>
                        <router-link to="/nasil-calisir" class="content-text">
                            Diziyle Öğren Nedir? Nasıl Çalışır?
                        </router-link>
                    </div>
                    <div class="content">
                        <router-link to="/iletisim" class="title">
                            İLETİŞİM
                        </router-link>
                        <!-- 
                        <div class="social-media-links">
                            <img style="width: 30px;" alt="Mail" src='@/assets/Footer/mail.svg' />
                            <a href="mailto: destek@diziyleogren.com"
                                class="content-text text-decoration-none">destek@diziyleogren.com</a>
                        </div>
                        -->
                        <span class="title">
                            SOSYAL MEDYA
                        </span>
                        <div class="social-media-links">
                            <a target="_blank" href="https://www.facebook.com/diziyleogren/">
                                <i class="fab fa-facebook-square text-light" style="font-size: 30px;"></i>
                            </a>
                            <a target="_blank" href="https://www.instagram.com/diziyleogren/">
                                <i class="fab fa-instagram-square text-light" style="font-size: 30px;"></i>
                            </a>
                            <a target="_blank" href="https://www.linkedin.com/company/diziyleogren">
                                <i class="fab fa-linkedin text-light" style="font-size: 30px;"></i>
                            </a>
                            <a target="_blank" href="https://twitter.com/diziyleogren">
                                <i class="fab fa-x-twitter text-light" style="font-size: 30px;"></i>
                            </a>
                        </div>
                        <a target="_blank"
                            href="https://play.google.com/store/apps/details?id=com.diziyleogren.diziyleogren">
                            <img class="mobile-badge" alt="Google Play'den alın"
                                src='@/assets/Home/google-play-badge.svg' />
                        </a>
                        <a target="_blank" href="https://apps.apple.com/tr/app/diziyleogren/id6444682457">
                            <img class="mobile-badge" alt="App Store'dan alın"
                                src='@/assets/Home/app-store-badge.svg' /></a>

                    </div>
                </div>
                <div class="footer-down">
                    <hr>
                    <div class="content">
                        <router-link to="/gizlilikPolitikasi" class="text-decoration-none content-text">
                            GİZLİLİK POLİTİKASI
                        </router-link>
                        <router-link to="/atiflar" class="text-decoration-none content-text">
                            ATIFLAR
                        </router-link>

                        <p class="content-text">Copyright Diziyleogren.com © 2024. All Rights Reserved.</p>

                    </div>
                </div>

            </div>
            <div class="right-side-bar"></div>

        </nav>

    </footer>
</template>

<style scoped>
.nav-bg {
    background: #8887C6;
    background-image: url("~@/assets/Footer/footer-bg.svg");
    background-size: cover;
    padding-top: 24px;
    font-family: Roboto, system-ui, -apple-system, BlinkMacSystemFont, 'Segoe UI', Roboto, Oxygen, Ubuntu, Cantarell, 'Open Sans', 'Helvetica Neue', sans-serif;
}

.my-container {
    margin: 0px;
}

.content-bar {
    display: flex;
    flex-direction: column;
}

.content-bar .footer-up {
    display: flex;
    flex-direction: row;
    gap: 72px;
}


.content-bar .footer-up .content {
    display: flex;
    flex-direction: column;
    gap: 16px;
    min-width: 300px;
}

.content-bar .footer-up .content .title {
    color: var(--Gray-6, #F2F2F2);
    font-family: Roboto;
    font-size: 20px;
    font-style: normal;
    font-weight: 700;
    line-height: normal;
    text-decoration: none;
}

.content-bar .footer-up .content .content-text {
    color: var(--Gray-6, #F2F2F2);
    font-family: Roboto;
    font-size: 14px;
    font-style: normal;
    font-weight: 400;
    line-height: normal;
}

.content-bar .footer-up .content .social-media-links {
    display: flex;
    flex-direction: row;
    gap: 16px;
}


.content-bar .footer-down {
    display: flex;
    flex-direction: column;
    gap: 16px;
}

.content-bar .footer-down hr {
    color: #F2F2F2;
    margin: 0;
    margin-top: 16px;

}


.content-bar .footer-down .content {
    display: flex;
    flex-direction: row;
    justify-content: space-between;
}

.content-bar .footer-down .content .content-text {
    color: var(--Gray-6, #F2F2F2);
    font-family: Roboto;
    font-size: 12px;
    font-style: normal;
    font-weight: 400;
    line-height: normal;
}


.content-bar .footer-down hr {
    color: #F2F2F2;
    height: 2px;
}

.mobile-badge {
    width: 160px;
}

@media (max-width: 1000px) {
    .footer-up {
        flex-direction: column !important;
        gap: 32px !important;
    }

    .content-bar .footer-down .content {
        gap: 16px;
    }

    .content-bar .footer-down .content .content-text {
        font-size: 10px;
    }

}

@media (max-width: 1250px) {
    .content-bar .footer-up .content {
        min-width: 200px;
    }

}
</style>