import axios from "axios";
import router from "../../router/index";

const state = {
  DOData: { data: { series: [], movies: [] } },
  currentVersion: "",
  isDark: "",
};
const getters = {
  // state verisini çağırmak için kullanılır
  getDOData(state) {
    return state.DOData;
  },
  getIsDark(state) {
    return state.isDark;
  },
};
const mutations = {
  // state güncellemek için çalıştırılır. Senkron çalışır
  setDOData(state, payload) {
    state.DOData = payload;
  },
  setVersion(state, payload) {
    state.currentVersion = payload;
  },
  clearDOData(state) {
    state.DOData = "";
    localStorage.removeItem("DOData");
  },
  setIsDark(state, payload) {
    state.isDark = payload;
    localStorage.setItem("isDark", payload.toString());
  },
};
const actions = {
  // state güncellemek için çalıştırılır. Asenkron çalışır. DB işlemleri için
  downloadData({ commit, dispatch, state }) {
    axios.get("/api/Home/GetContent").then((response) => {
      let contentData = response.data;
      contentData["series"] = contentData["series"].reverse();
      contentData["movies"] = contentData["movies"].reverse();
      axios.get("/api/Home/GetContentVersion").then((response) => {
        let versionData = response.data;
        commit("setDOData", {
          data: contentData,
          version: versionData,
        });
        localStorage.setItem("DOData", JSON.stringify(state.DOData));
      });
    });
  },
  initDOData({ commit, state, dispatch }) {
    let DOData = JSON.parse(localStorage.getItem("DOData"));

    if (DOData) {
      axios
        .get("/api/Home/GetContentVersion")
        .then((response) => {
          commit("setVersion", response.data);
        })
        .finally(() => {
          if (DOData.version == state.currentVersion) {
            commit("setDOData", DOData);
          } else {
            commit("clearDOData");
            dispatch("downloadData");
          }
        });
    } else {
      dispatch("downloadData");
    }
  },
  initDark({ commit, state, dispatch }) {
    if (localStorage.getItem("isDark") == "true") {
      state.isDark = true;
    } else {
      state.isDark = false;
    }
  },
};

export default {
  state,
  getters,
  mutations,
  actions,
};
