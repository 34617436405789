<template>

  <Head>
    <component v-if="!isPremium" is="script"
      src="https://pagead2.googlesyndication.com/pagead/js/adsbygoogle.js?client=ca-pub-3579545649702539" async>
    </component>
  </Head>
  <div :class="{ 'dark': isDark }">
    <Header></Header>
    <router-view></router-view>
    <Footer></Footer>
  </div>
</template>
<script>
import Header from './components/Shared/Header';
import Footer from './components/Shared/Footer';
export default {
  data() {
    return {
    }
  },
  created() {
    this.$store.dispatch("initAuth")
    this.$store.dispatch("initDOData")
    this.$store.dispatch("initDark")
  },
  methods: {

  },
  computed: {
    isPremium() {
      return this.$store.getters.getUserData.isPremium
    },
    isDark() {
      return this.$store.getters.getIsDark
    }
  },
  components: {
    Header: Header,
    Footer: Footer
  },
}

</script>
<style></style>
