export function similarity(s1, s2) {
  var longer = s1;
  var shorter = s2;
  if (s1.length < s2.length) {
    longer = s2;
    shorter = s1;
  }
  var longerLength = longer.length;
  if (longerLength == 0) {
    return 1.0;
  }
  return (
    (longerLength - editDistance(longer, shorter)) / parseFloat(longerLength)
  );
}

function editDistance(s1, s2) {
  s1 = s1.toLowerCase();
  s2 = s2.toLowerCase();

  var costs = new Array();
  for (var i = 0; i <= s1.length; i++) {
    var lastValue = i;
    for (var j = 0; j <= s2.length; j++) {
      if (i == 0) costs[j] = j;
      else {
        if (j > 0) {
          var newValue = costs[j - 1];
          if (s1.charAt(i - 1) != s2.charAt(j - 1))
            newValue = Math.min(Math.min(newValue, lastValue), costs[j]) + 1;
          costs[j - 1] = lastValue;
          lastValue = newValue;
        }
      }
    }
    if (i > 0) costs[s2.length] = lastValue;
  }
  return costs[s2.length];
}

export function prepareWorkingTypeObjects(payload) {
  let working_type_object_list = [];
  payload.forEach((element) => {
    if (element == "test-phrase-mean") {
      working_type_object_list.push({
        name: "test-phrase-mean",
        DifficultyForSorting: 0,
        StartPointForScoring: 60,
        DurationForScoring: 40,
      });
    }
    if (element == "test-definition-phrase") {
      working_type_object_list.push({
        name: "test-definition-phrase",
        DifficultyForSorting: 1,
        StartPointForScoring: 90,
        DurationForScoring: 45,
      });
    }
    if (element == "test-synonyms-phrase") {
      working_type_object_list.push({
        name: "test-synonyms-phrase",
        DifficultyForSorting: 2,
        StartPointForScoring: 80,
        DurationForScoring: 40,
      });
    }
    if (element == "write-phrase-mean") {
      working_type_object_list.push({
        name: "write-phrase-mean",
        DifficultyForSorting: 3,
        StartPointForScoring: 120,
        DurationForScoring: 55,
      });
    }
    if (element == "write-mean-phrase") {
      working_type_object_list.push({
        name: "write-mean-phrase",
        DifficultyForSorting: 4,
        StartPointForScoring: 140,
        DurationForScoring: 55,
      });
    }
    if (element == "write-definition-phrase") {
      working_type_object_list.push({
        name: "write-definition-phrase",
        DifficultyForSorting: 5,
        StartPointForScoring: 180,
        DurationForScoring: 70,
      });
    }
    if (element == "writeListen-engAudio-phrase") {
      working_type_object_list.push({
        name: "writeListen-engAudio-phrase",
        DifficultyForSorting: 6,
        StartPointForScoring: 190,
        DurationForScoring: 70,
      });
    }
    if (element == "writeListen-engAudio-mean") {
      working_type_object_list.push({
        name: "writeListen-engAudio-mean",
        DifficultyForSorting: 7,
        StartPointForScoring: 200,
        DurationForScoring: 70,
      });
    }
  });
  working_type_object_list.sort(function (a, b) {
    return a.DifficultyForSorting - b.DifficultyForSorting;
  });
  return working_type_object_list;
}

export function prepareQuestionObject(
  selected,
  working_type_list,
  decreasingList
) {
  // son soru listesi
  let question_list = [];

  working_type_list.forEach((working_type) => {
    if (working_type.name == "test-phrase-mean") {
      // daha sonra şıklar listesini oluşturmak için kullanacağımız kelime anlamlarını bir listeye alıyoruz
      // tekrar eden anlamlar varsa kaldırıyoruz
      let word_means = decreasingList.map((a) => a.mean);
      word_means = [...new Set(word_means)];

      var new_list = selected.map(function (element) {
        // anlamlar listesideki kelimelerin, sorulan kelimenin anlamına string olarak benzerliklerini çıkarıyoruz
        let similarity_list = word_means.map(function (word) {
          let similarity_ratio = similarity(word, element.mean);
          return { word: word, similarity_ratio: similarity_ratio };
        });

        // anlamları en çok benzerdir en az benzere doğru sıralıyoruz. Böylece kelimenin doğru anlamı ilk sıraya geliyor.
        let sorted_similarity = similarity_list.sort(function (a, b) {
          return (
            parseFloat(b.similarity_ratio) - parseFloat(a.similarity_ratio)
          );
        });

        // kelimenin doğru anlamı ilk sırada. Diğer 3 de en çok benzeyenler. Bunları şıklar listesine alıyoruz.
        let choices_list = sorted_similarity.slice(0, 4);

        // şıklar listesini karıştırıyoruz.
        choices_list = choices_list.sort(() => 0.5 - Math.random());

        // soru objesinin son halini veriyoruz
        var new_element = {
          ...element,
          choices_list: choices_list,
          question: element.phrase,
          trueChoice: element.mean,
          StartPointForScoring:
            working_type.StartPointForScoring + element.difficulty * 10,
          DurationForScoring: working_type.DurationForScoring,
          my_true_count: 0,
          falseCount: 0,
          repetitionCount: 0,
        };

        return new_element;
      });
      question_list.push(...new_list);
    } else if (working_type.name == "test-definition-phrase") {
      // daha sonra şıklar listesini oluşturmak için kullanacağımız kelime phraseleri bir listeye alıyoruz
      // tekrar eden phrase varsa kaldırıyoruz
      let word_phrases = decreasingList.map((a) => a.phrase);
      word_phrases = [...new Set(word_phrases)];
      let new_list = selected
        .filter(function (element) {
          // aşağıda definition verisi olmayan kelimeleri atla diyoruz.
          if (!element.definition) {
            return false; // skip
          }
          return true;
        })
        .map(function (element) {
          // phrases listesideki kelimelerin, sorulan kelimenin phrase string olarak benzerliklerini çıkarıyoruz
          let similarity_list = word_phrases.map(function (word) {
            let similarity_ratio = similarity(word, element.phrase);
            return { word: word, similarity_ratio: similarity_ratio };
          });

          // phrase en çok benzerdir en az benzere doğru sıralıyoruz. Böylece kelimenin doğru anlamı ilk sıraya geliyor.
          let sorted_similarity = similarity_list.sort(function (a, b) {
            return (
              parseFloat(b.similarity_ratio) - parseFloat(a.similarity_ratio)
            );
          });

          // kelimenin doğru anlamı ilk sırada. Diğer 3 de en çok benzeyenler. Bunları şıklar listesine alıyoruz.
          let choices_list = sorted_similarity.slice(0, 4);

          // şıklar listesini karıştırıyoruz.
          choices_list = choices_list.sort(() => 0.5 - Math.random());

          // soru objesinin son halini veriyoruz. Proxy obje yapısından kurtulmak için böyle yaptık. Yoksa her turdaki aynı obje oluyor oto güncelliyor

          var new_element = {
            ...element,
            choices_list: choices_list,
            question: element.definition,
            trueChoice: element.phrase,
            StartPointForScoring:
              working_type.StartPointForScoring + element.difficulty * 10,
            DurationForScoring: working_type.DurationForScoring,
            my_true_count: 0,
            falseCount: 0,
            repetitionCount: 0,
          };

          return new_element;
        });
      question_list.push(...new_list);
    } else if (working_type.name == "test-synonyms-phrase") {
      // daha sonra şıklar listesini oluşturmak için kullanacağımız kelime phraseleri bir listeye alıyoruz
      // tekrar eden phrase varsa kaldırıyoruz
      let word_phrases = decreasingList.map((a) => a.phrase);
      word_phrases = [...new Set(word_phrases)];
      let new_list = selected
        .filter(function (element) {
          // aşağıda synonyms verisi olmayan kelimeleri atla diyoruz.
          if (!element.synonyms) {
            return false; // skip
          }
          return true;
        })
        .map(function (element) {
          // phrases listesideki kelimelerin, sorulan kelimenin phrase string olarak benzerliklerini çıkarıyoruz
          let similarity_list = word_phrases.map(function (word) {
            let similarity_ratio = similarity(word, element.phrase);
            return { word: word, similarity_ratio: similarity_ratio };
          });

          // phrase en çok benzerdir en az benzere doğru sıralıyoruz. Böylece kelimenin doğru anlamı ilk sıraya geliyor.
          let sorted_similarity = similarity_list.sort(function (a, b) {
            return (
              parseFloat(b.similarity_ratio) - parseFloat(a.similarity_ratio)
            );
          });

          // kelimenin doğru anlamı ilk sırada. Diğer 3 de en çok benzeyenler. Bunları şıklar listesine alıyoruz.
          let choices_list = sorted_similarity.slice(0, 4);

          // şıklar listesini karıştırıyoruz.
          choices_list = choices_list.sort(() => 0.5 - Math.random());

          // soru objesinin son halini veriyoruz. Proxy obje yapısından kurtulmak için böyle yaptık. Yoksa her turdaki aynı obje oluyor oto güncelliyor

          var new_element = {
            ...element,
            choices_list: choices_list,
            question: element.synonyms,
            trueChoice: element.phrase,
            StartPointForScoring:
              working_type.StartPointForScoring + element.difficulty * 10,
            DurationForScoring: working_type.DurationForScoring,
            my_true_count: 0,
            falseCount: 0,
            repetitionCount: 0,
          };

          return new_element;
        });
      question_list.push(...new_list);
    } else if (working_type.name == "write-phrase-mean") {
      let new_list = selected.map(function (element) {
        // soru objesinin son halini veriyoruz. Proxy obje yapısından kurtulmak için böyle yaptık. Yoksa her turdaki aynı obje oluyor oto güncelliyor

        var new_element = {
          ...element,
          question: element.phrase,
          trueChoice: element.mean,
          StartPointForScoring:
            working_type.StartPointForScoring + element.difficulty * 10,
          DurationForScoring: working_type.DurationForScoring,
          my_true_count: 0,
          falseCount: 0,
          repetitionCount: 0,
        };

        return new_element;
      });
      question_list.push(...new_list);
    } else if (working_type.name == "write-mean-phrase") {
      let new_list = selected.map(function (element) {
        // soru objesinin son halini veriyoruz. Proxy obje yapısından kurtulmak için böyle yaptık. Yoksa her turdaki aynı obje oluyor oto güncelliyor

        var new_element = {
          ...element,
          question: element.mean,
          trueChoice: element.phrase,
          StartPointForScoring:
            working_type.StartPointForScoring + element.difficulty * 10,
          DurationForScoring: working_type.DurationForScoring,
          my_true_count: 0,
          falseCount: 0,
          repetitionCount: 0,
        };

        return new_element;
      });
      question_list.push(...new_list);
    } else if (working_type.name == "write-definition-phrase") {
      let new_list = selected.map(function (element) {
        // soru objesinin son halini veriyoruz. Proxy obje yapısından kurtulmak için böyle yaptık. Yoksa her turdaki aynı obje oluyor oto güncelliyor

        var new_element = {
          ...element,
          question: element.definition,
          trueChoice: element.phrase,
          StartPointForScoring:
            working_type.StartPointForScoring + element.difficulty * 10,
          DurationForScoring: working_type.DurationForScoring,
          my_true_count: 0,
          falseCount: 0,
          repetitionCount: 0,
        };

        return new_element;
      });
      question_list.push(...new_list);
    } else if (working_type.name == "writeListen-engAudio-phrase") {
      let new_list = selected.map(function (element) {
        // soru objesinin son halini veriyoruz. Proxy obje yapısından kurtulmak için böyle yaptık. Yoksa her turdaki aynı obje oluyor oto güncelliyor

        var new_element = {
          ...element,
          question: element.phrase,
          trueChoice: element.phrase,
          StartPointForScoring:
            working_type.StartPointForScoring + element.difficulty * 10,
          DurationForScoring: working_type.DurationForScoring,
          audio_link_answer: "phrase",
          my_true_count: 0,
          falseCount: 0,
          repetitionCount: 0,
        };

        return new_element;
      });
      question_list.push(...new_list);
    } else if (working_type.name == "writeListen-engAudio-mean") {
      let new_list = selected.map(function (element) {
        // soru objesinin son halini veriyoruz. Proxy obje yapısından kurtulmak için böyle yaptık. Yoksa her turdaki aynı obje oluyor oto güncelliyor

        var new_element = {
          ...element,
          question: element.phrase,
          trueChoice: element.mean,
          StartPointForScoring:
            working_type.StartPointForScoring + element.difficulty * 10,
          DurationForScoring: working_type.DurationForScoring,
          audio_link_answer: "mean",
          my_true_count: 0,
          falseCount: 0,
          repetitionCount: 0,
        };

        return new_element;
      });
      question_list.push(...new_list);
    }
  });
  return question_list;
}
